import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Tags Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['show', 'selectField']

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  edit(e) {
    console.log(e.currentTarget)
    const target = e.currentTarget
    console.log(this.showTarget)
    // console.log(this.formTarget)
    const form = document.getElementById(`${target.dataset.model}-${target.dataset.id}-tags-form`)
    console.log({form})
    this.showTarget.classList.add('hidden')
    form.classList.remove('hidden')
  }

  save(e) {
    console.log(this.selectFieldTarget.value)
    const target = this.selectFieldTarget
    this.stimulate('Tags#save', target).then(res => {
      document.getElementById(`${target.dataset.model}-${target.dataset.id}-tags-show`).classList.remove('hidden')
      document.getElementById(`${target.dataset.model}-${target.dataset.id}-tags-form`).classList.add('hidden')
    })
  }
}
