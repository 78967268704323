// ./controllers/flatpickr_controller.js
// Extending stimulus-flatpickr controller
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
// import MonthSelectPlugin from 'FlatpickerPlugins/monthSelect';
import monthSelect from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

// you can also import a translation file
// import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/flatpickr.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      plugins: [new monthSelect({shorthand: true, dateFormat: "Y/m/01", altFormat: "F Y"})]
    }
  }

  // connect() {

  // }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }
}
