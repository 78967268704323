import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Navbar Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "profileDropdown" ]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  toggleProfile(e) {
    // DEPRACATED. USING stimulus dropdown component
    // console.log('target?', this.profileDropdownTarget)
    this.profileDropdownTarget.classList.toggle('hidden')
  }
}
