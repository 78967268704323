import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the MapPopup Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['chevron']
  connect () {
    super.connect()
    // add your code here, if applicable
    // console.log('chevron', this.chevronTarget)
  }

  toggle(e) {
    console.log('toggle popup')
    if (this.hasChevronTarget) {
      if (this.chevronTarget.classList.contains('rotate-180')) {
        this.chevronTarget.classList.remove('rotate-180');
        this.stimulate('MapPopup#open', e.target)
      } else {
        this.chevronTarget.classList.add('rotate-180');
        this.stimulate('MapPopup#close')
      }
    }
  }
}
