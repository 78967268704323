import ApplicationController from './application_controller'
import { initChoices } from '../plugins/init-choices'
import Swal from 'sweetalert2'
// import Sortable from "sortablejs"
/* This is the custom StimulusReflex controller for the Passages Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'trix', 'questionTrixEditor', 'addQuestion', 'questionTypeButtons', 'mcq', 'frq', 'questionGroup', 'answerGroup', 'form' ]
  static values = {
    checkIfPublished: Boolean,
    isPublished: Boolean,
    showLineNumbers: Boolean,
  }

  connect () {
    super.connect()
    // document.addEventListener('optimism:form:invalid', event => console.log('optimism:form:invalid', event))
    // document.addEventListener('optimism:hello', event => console.log('optimism:hello', event))
    // document.addEventListener('optimism:attribute:invalid', event => {
    //   // console.log('optimism:attribute:invalid', event)
    //   console.log('optimism:attribute:invalid', event.detail)
    // })
    // add your code here, if applicable

    if (this.hasTrixTarget && (this.hasShowLineNumbersValue && this.showLineNumbersValue)) {
      console.log('passageText found')
      setTimeout(() => {
        const trix = this.trixTarget //document.querySelector('trix-editor')
        this.stimulate('Passages#keyup', trix.value)
      }, 400)
    }
    if (this.hasQuestionTypeButtonsTarget) {
      // console.log('question type buttons found', this.questionTypeButtonsTarget)
      const input = this.questionTypeButtonsTarget.closest('trix-toolbar').parentNode.querySelector('input[data-field="question_type"]')
      // console.log({input})
      if (input.value == 'mcq') {
        this.mcqTarget.classList.add('trix-active')
      } else {
        this.frqTarget.classList.add('trix-active')
      }
    }
  }

  endAnswerSort(e) {
    console.log(e.item)
    this.stimulate('Passages#sort_item', 'answer', e.item.dataset.id, e.newIndex + 1).then(res => initChoices())
  }

  endSort(e) {
    console.log(e.item)
    console.log(e.item.dataset)
    console.log(e.newIndex)
    this.stimulate('Passages#sort_item', this.questionGroupTarget, 'question', e.item.dataset.id, e.newIndex + 1).then(res => initChoices())
  }

  triggerTag(e) {
    console.log('trigger tag', e)
  }

  triggerUploadImage(e) {
    const btn = e.currentTarget
    console.log(1, btn.closest('trix-toolbar'))
    console.log(2, btn.closest('trix-toolbar').parentNode)
    console.log(3, btn.closest('trix-toolbar').parentNode.parentNode)
    console.log(4, btn.closest('trix-toolbar').parentNode.parentNode.parentNode)
    // console.log(btn.closest('trix-toolbar').parentNode.parentNode.parentNode)
    const input = btn.closest('trix-toolbar').parentNode.parentNode.parentNode.querySelector('input[type="file"]')
    console.log(input)
    // const { questionId } = input.dataset
    input.click()
  }

  uploadImage(e) {
    this.loading()
    // console.log(e.currentTarget.dataset)
    const target = e.currentTarget
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    console.log('files', target.files)

    let formData = new FormData()
    const files = Array.from(target.files);
    files.forEach(file => {
      formData.append('images[]', file)
    })
    // const file = target.files[0]

    // let formData = new FormData();
    // formData.append('image', file)
    formData.append('model', target.dataset.model)
    formData.append('id', target.dataset.id)

    console.log({formData})

    const url = `/add_content/upload_images`

    fetch(url, {
      method: 'post',
      body: formData,
      headers: {"X-CSRF-TOKEN": csrf}
    }).then(response => response.json()).then(res => {
      console.log(res)
      this.stimulate('Passages#show_image', target).then(res => this.loading('hide'))
    })
  }

  removeImage(e) {
    e.stopPropagation();
    console.log(e.currentTarget.dataset)
    this.stimulate('Passages#remove_image', e.currentTarget)
  }

  selectQuestionType(e) {
    // console.log(e.currentTarget.dataset.questionType)

    const input = this.questionTypeButtonsTarget.closest('trix-toolbar').parentNode.querySelector('input[data-field="question_type"]')
    // console.log(input.value)

    if (e.currentTarget.dataset.questionType == 'mcq' && input.value !== 'mcq') {
      if (confirm('Change this question to MCQ?')) {
        input.value = 'mcq'
        this.selectQuestionTypeButton(e)
        this.stimulate('Passages#change_question_type', input.dataset.fqIndex, input.dataset.questionId, 'mcq', input.dataset.model)
      }
    } else if (e.currentTarget.dataset.questionType == 'frq' && input.value !== 'frq') {
      if (confirm(`Change this question to FRQ? This will delete all the answer choices.`)) {
        input.value = 'frq'
        this.selectQuestionTypeButton(e)
        this.stimulate('Passages#change_question_type', input.dataset.fqIndex, input.dataset.questionId, 'frq', input.dataset.model)
      }
    }
  }

  selectQuestionTypeButton(e) {
    e.currentTarget.classList.add('trix-active')
    if (e.currentTarget.nextElementSibling) e.currentTarget.nextElementSibling.classList.remove('trix-active')
    if (e.currentTarget.previousElementSibling) e.currentTarget.previousElementSibling.classList.remove('trix-active')
  }

  addQuestion(e) {
    // console.log(this.addQuestionTarget.parentNode)
    const target = this.addQuestionTarget
    // console.log(target.parentNode.firstChild)
    const newQuestionEl = document.createElement('div')
    target.dataset.lastIndex = parseInt(target.dataset.lastIndex) + 1
    const elPrefix = (target.dataset.model && target.dataset.model == 'LessonPassageQuestion') ? 'lesson_' : ''
    newQuestionEl.setAttribute('id', `${elPrefix}passage-${target.dataset.slug}-question-${target.dataset.lastIndex}`)
    newQuestionEl.setAttribute('class', "grid grid-cols-1 gap-4 h-fit pb-4")
    // console.log(newQuestionEl.id)
    // target.parentNode.insertBefore(newQuestionEl, target)
    target.parentNode.firstChild.appendChild(newQuestionEl)
    this.stimulate('Passages#add_question', newQuestionEl.id)
  }

  selectCorrectAnswer(e) {
    const headers = this.getCsrfHeaders()
    const { dataset } = e.currentTarget
    console.log({ dataset })
    console.log('checkIfPublished', this.checkIfPublishedValue)
    console.log('checkIfPublished type', typeof this.checkIfPublishedValue)
    // if (dataset.checkIfPublished == 'true')
    if (this.checkIfPublishedValue) {
      fetch(`/questions/${dataset.questionId}/check_if_test_published`, { headers }).then(res => {
        if (res) alert("Test is already published, you cannot unselect an answer.")
      })
    } else {
      this.stimulate('Passages#select_correct_answer', e.currentTarget)
    }
  }

  removeQuestion(e) {
    // console.log(e.currentTarget.dataset)
    const target = e.currentTarget
    const {id} = target.dataset
    fetch(`/questions/${id}/check_responses`).then(res => res.json()).then(res => {
      console.log(res)
      if (res) {
        Swal.fire({
          title: 'STOP!',
          text: `This question has existing student responses. Are you really sure you want to delete it?`,
          icon: 'error',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#57A773',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Yes, DELETE',
          confirmButtonColor: '#D64933',
        }).then(result => {
          if (result.isConfirmed) {
            console.log("confirmed please delete")
            this.stimulateRemoveQuestion(target)
          }
        })
      } else {
        Swal.fire({
          // title: 'STOP!',
          text: `Are you sure you want to delete this question?`,
          icon: 'warning',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#57A773',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Yes, DELETE',
          confirmButtonColor: '#D64933',
        }).then(result => {
          if (result.isConfirmed) {
            console.log("confirmed please delete")
            this.stimulateRemoveQuestion(target)
          }
        })
      }
    })
  }

  stimulateRemoveQuestion(target) {
    this.stimulate('Passages#remove_question', target).then(res => {
      const addQ = document.getElementById(target.dataset.addQEl)
      addQ.dataset.lastIndex = parseInt(addQ.dataset.lastIndex) - 1
    })
  }

  addAnswer(e) {
    // console.log(e.currentTarget.dataset)
    const data = e.currentTarget.dataset
    const newElSuffix = data.model == 'Question' ? `question-${data.questionId}-answer` : `lesson_passage_question-${data.questionId}-answer`
    const newIndex = parseInt(data.lastIndex) + 1
    const newAnswerEl = document.createElement('div')
    newAnswerEl.setAttribute('id', `${newElSuffix}-${newIndex}`)
    // newAnswerEl.setAttribute('class', `flex space-x-4 ${newElSuffix}`)
    newAnswerEl.setAttribute('class', `grid grid-cols-1 gap-4 h-fit pb-4`)
    e.currentTarget.parentNode.insertBefore(newAnswerEl, e.currentTarget)
    e.currentTarget.dataset.lastIndex = newIndex
    this.stimulate('Passages#add_answer', e.currentTarget, newAnswerEl.id, newIndex)
  }

  removeAnswer(e) {
    const target = e.currentTarget
    const answers = document.querySelectorAll(`.${target.dataset.className}`)
    const { answerId } = target.dataset
    const el = target.parentNode.querySelector('trix-editor')
    // console.log(el)
    const doc = el?.editor?.getDocument()
    const text = doc?.toString()?.trim();
    // console.log(text !== '' ? text : 'Text is empty')
    // console.log('count answers', answers.length)
    // console.log(target.dataset)
    if (answers.length <= 2) {
      Swal.fire({
        // title: 'STOP!',
        text: `Must have at least 2 answers`,
        icon: 'error',
        reverseButtons: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#57A773',
      })
    } else {
      console.log('removeAnswer id', answerId)
      fetch(`/answer_choices/${answerId}/check_responses`).then(res => res.json()).then(res =>{
        console.log(res)
        if (res) {
          Swal.fire({
            title: 'STOP!',
            text: `This answer has existing student responses. Are you really sure you want to delete it?`,
            icon: 'error',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#57A773',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Yes, DELETE',
            confirmButtonColor: '#D64933',
          }).then(result => {
            if (result.isConfirmed) {
              console.log("confirmed please delete")
              this.stimulate('Passages#remove_answer', target)
            }
          })
        } else if (text !== null && text?.length > 0) {
          Swal.fire({
            // title: 'STOP!',
            text: `Are you sure you want to delete this answer?`,
            icon: 'warning',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#57A773',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Yes, DELETE',
            confirmButtonColor: '#D64933',
          }).then(result => {
            if (result.isConfirmed) {
              console.log("confirmed please delete")
              this.stimulate('Passages#remove_answer', target)
            }
          })
        } else {
          this.stimulate('Passages#remove_answer', target)
        }
      })
    }

  }

  keyup(e) {
    // console.log(e.target.value)
    // console.log('this.hasShowLineNumbersValue', this.hasShowLineNumbersValue)
    // console.log('this.showLineNumbersValue', this.showLineNumbersValue)
    e.stopPropagation()
    if (this.hasShowLineNumbersValue && this.showLineNumbersValue) {
      const trix = this.trixTarget //document.querySelector('trix-editor')
      const doc = trix.editor.getDocument().documentElement
      // console.log(trix.editor.getDocument().toString())
      // this.stimulate('Passages#keyup', doc)
      this.stimulate('Passages#keyup', e.target.value)
    }
  }

  parseToQA(e) {
    // if (this.hasAddQuestionTarget) {
    //   const addQEl = this.addQuestionTarget;
    //   console.log(123, addQEl)
    // }
    const addQEl = document.getElementById(e.target.dataset.addQEl);
    // console.log(addQEl)
    // console.log('lastIndex', addQEl.dataset.lastIndex)
    const lastIndex = parseInt(addQEl.dataset.lastIndex)
    // console.log('lastIndex2', lastIndex)

    if (this.hasQuestionTrixEditorTarget) {

      const el = this.questionTrixEditorTarget
      // el.value = ''
      // console.log(el.dataset)
      const elId = el.getAttribute('input')
      const input = document.getElementById(elId)
      // console.log(el.editor)
      const doc = el.editor.getDocument()
      const text = doc.toString();
      // console.log(text)
      // const regex = /^\d{1,2}\.*/gm;
      const regex = /^\s*\d{1,3}\.*/gm;
      const res = text.match(regex);
      console.log({ res })
      // if (res) {
        // console.log('length', res.length)
        if (res === null || res?.length == 1) {
          console.log('go to parse single')
          this.loading();
          this.stimulate('Passages#parse_single_question', input, text).then(this.loading('hide'))
        } else if (res.length > 1) {
          console.log('go to parse multiple')
          this.loading();
          const newIndex = lastIndex + (res.length) - 1;
          console.log('newIndex', newIndex)
          addQEl.dataset.lastIndex = newIndex
          this.stimulate('Passages#parse_multiple_questions', input, text).then(reflexRes => {
            console.log('finished parsing multiple questions')
            addQEl.dataset.lastIndex = newIndex;
            this.loading('hide');
          })
        }
      // }
      // this.stimulate('Passages#clean_and_parse_question_and_answers', input, doc.toString())
    }
  }

  savePassageText(e) {
    const elId = e.target.getAttribute('input')
    const input = document.getElementById(elId)
    // console.log(input)
    this.stimulate('Passages#auto_save_passage_text', e.target, input.value)
  }

  sendValue(e) {
    // console.log(e.target)
    // console.log(e.target.id)

    // console.log(e.target.getAttribute('input'))
    const elId = e.target.getAttribute('input')
    const input = document.getElementById(elId)
    // console.log(input)
    this.stimulate('Passages#auto_save_qa', input)
  }

  expandSectionType(e) {
    const expand = e.target.classList.contains('not-expanded')
    console.log({ expand })
    if (expand) {
      e.target.classList.remove('not-expanded')
      this.stimulate('Passages#expand_section_type', e.target, expand)
    } else {
      e.target.classList.add('not-expanded')
      this.stimulate('Passages#expand_section_type', e.target, expand)
    }
  }

  expandSectionLevel(e) {
    const expand = e.target.classList.contains('not-expanded')
    console.log({ expand })
    if (expand) {
      e.target.classList.remove('not-expanded')
      this.stimulate('Passages#expand_section_level', e.target, expand)
    } else {
      e.target.classList.add('not-expanded')
      this.stimulate('Passages#expand_section_level', e.target, expand)
    }
  }

  deletePasage(e) {
    // console.log(e.target.dataset)
    const { passageSlug, testSlug } = e.target.dataset;
    // loading()
    const headers = this.getCsrfHeaders()

    fetch(`/passages/${passageSlug}/check_responses`, {
      headers
    }).then(res => res.json()).then(res => {
      if (res) {
        Swal.fire({
          title: 'STOP!',
          text: `This passage has already been used in lessons/exams, and has existing student responses. Are you really sure you want to delete it?`,
          icon: 'error',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#57A773',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Yes, DELETE',
          confirmButtonColor: '#D64933',
        }).then(result => {
          if (result.isConfirmed) {
            console.log("confirmed please delete")
            // window.location.href = `/add_content/tests/${testSlug}`
            this.fetchDeletePassage(passageSlug, testSlug)
          } else {
            console.log("cancelled")
          }
        })
      } else {
        Swal.fire({
          text: `Are you sure you want to delete this passage?`,
          icon: 'error',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#57A773',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Yes, DELETE',
          confirmButtonColor: '#D64933',
        }).then(result => {
          if (result.isConfirmed) {
            console.log("confirmed please delete")
            // window.location.href = `/add_content/tests/${testSlug}`
            this.fetchDeletePassage(passageSlug, testSlug)
          }
        })
      }
    })
  }

  fetchDeletePassage(passageSlug, testSlug) {
    const url = `/passages/${passageSlug}?format=json`
    const headers = this.getCsrfHeaders()
    // console.log('in fetchDeletePassage url', url)
    fetch(url, { headers: headers, method: 'DELETE' }).then(res => res.json()).then(res => {
      // console.log(res)
      window.location.href = `/add_content/tests/${testSlug}`
    })
  }

  async trySubmitForm(e) {
    e.preventDefault();
    // e.stopPropagation();
    const href =  e.currentTarget.href;
    const published = this.isPublishedValue;
    const buttonValue = e.target.dataset.buttonValue;
    const headers = this.getCsrfHeaders()
    const check = await fetch(`/passages/${this.formTarget.dataset.passageSlug}/check_responses`, {
      headers
    }).then(res => res.json())
    console.log('check', check);

    if (published && buttonValue == 'next') {
      const text = `This test has been marked as 'ready for use'${check ? ' and is <strong>being used in lessons/exams</strong>' : ''}.<br>Are you sure you want to add a new passage?`;
      this.submitSwalWarning(text).then(result => {
        // if (result.isConfirmed) this.submitForm(buttonValue)
        if (result.isConfirmed) {
          window.location.href = href;
        }
      })
    } else if (published && buttonValue == 'preview') {
      console.log('HELLO')
      // TODO, maybe check answer keys etc?
      const text = `This test is ready for use in courses.<br>Changes will update the content where it is used in past and current courses.<br>Are you sure you want to make any changes?`
      this.submitSwalWarning(text).then(result => {
        // if (result.isConfirmed) this.submitForm(buttonValue)
        if (result.isConfirmed) {
          window.location.href = href;
        }
      })
    } else {
      // this.submitForm(buttonValue);
      window.location.href = href;
    }

  }

  submitForm(buttonValue) {
    fetch(this.formTarget.action, {
      method: "PATCH", // Could be dynamic with Stimulus values
      headers: { "Accept": "application/json" },
      body: new FormData(this.formTarget)
    })
      .then(response => response.json())
      .then((data) => {
        console.log('res data', data)
        if (buttonValue == 'next') {
          window.location.href = data.redirect_next;
        } else {
          window.location.href = data.redirect_preview;
        }
      })
  }

  submitSwalWarning(text) {
    return Swal.fire({
      title: 'STOP!',
      // text: text,
      html: text,
      icon: 'error',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#57A773',
      showCancelButton: true,
      // reverseButtons: true,
      confirmButtonText: 'YES',
      confirmButtonColor: '#D64933',
    })
  }

  afterExpandSectionType(element, reflex, noop, reflexId) {
    initChoices();
  }

  afterReflex (element, reflex, noop, reflexId) {
    // console.log('HELLO AFTER-REFLEX in PassagesController')
  }
}
