import ApplicationController from './application_controller'
// import Choices from 'choices.js'
import TomSelect from "tom-select";
// import { get, post, put, patch, destroy } from '@rails/request.js'

/* This is the custom StimulusReflex controller for the ExamPopupSearch Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
    console.log('connected to exam popup controller')
    console.log('search url', this.element.dataset.searchUrl)
    const initialOptions = JSON.parse(this.element.dataset.tests)
    console.log({ initialOptions })
    let searchUrl = this.element.dataset.searchUrl;
    this.tomSelect = new TomSelect(this.element, {
      valueField: 'value',
      labelField: 'label',
      searchField: 'label',
      options: initialOptions,
      load: function(query, callback) {
        const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const url = `${searchUrl}&query=` + encodeURIComponent(query);
        fetch(url)
          .then(response => response.json())
          .then(json => {
            console.log('json', json)
            callback(json.tests);
          }).catch(()=>{
            callback();
          });
      },
      // custom rendering functions for options and items
      render: {
        option: function(item, escape) {
          return `<div class="py-2 d-flex">
                <div>
                  <div class="mb-1">
                    <span class="text-sm font-medium">
                      ${ escape(item.label) }
                    </span>
                  </div>
                  <div class="text-xs">${ escape(item.description) }</div>
                </div>
              </div>`;
        },
        item: function(item, escape) {
          return `<div class="py-2 d-flex">
                <div>
                  <div class="mb-1">
                    <span class="text-sm font-medium">
                      ${ escape(item.label) }
                    </span>
                  </div>
                  <div class="text-xs">${ escape(item.description) }</div>
                </div>
              </div>`;
        }
      },
    })
  }

}
