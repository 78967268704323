import ApplicationController from './application_controller'

export default class extends ApplicationController {
  // static targets = [ 'responseContainer', 'fakeResponseContainer', 'richTextArea' ]
  connect () {
    super.connect()
    // add your code here, if applicable
    this._replicateTextAreasContent()
  }

  // toggleEdit(e) {
  //   e.preventDefault()
  //   const { actionName } = e.currentTarget.dataset;
  //   if (actionName == 'cancel') {
  //     this._toggleEssayResponseHiddenClasses()
  //   } else if (confirm(`Are you sure you want to modify this student's essay response?`)) {
  //     this._toggleEssayResponseHiddenClasses()
  //   }
  // }

  // _toggleEssayResponseHiddenClasses() {
  //   if (this.hasResponseContainerTarget && this.hasFakeResponseContainerTarget) {
  //     this.responseContainerTarget.classList.toggle('hidden')
  //     this.fakeResponseContainerTarget.classList.toggle('hidden')
  //   }
  // }

  // showEdit(e) {
  //   if (confirm(`Are you sure you want to modify this student's essay response?`)) {
  //     this.stimulate('Essay#show_edit_response', e.target)
  //   }
  // }

  // saveResponse(e) {
  //   e.preventDefault()
  //   console.log('save response logic')
  //   const inputId = this.richTextAreaTarget.getAttribute('input');
  //   const input = document.getElementById(inputId);
  //   const lqrId = this.richTextAreaTarget.dataset.lqrId
  //   console.log('input.value', input.value)
  //   console.log('lqrId', lqrId)
  //   this.stimulate('Essay#save_essay_response', lqrId, input.value).then(_ => {
  //     console.log('response saved')
  //     this._toggleEssayResponseHiddenClasses()
  //   })
  // }
  
  _replicateTextAreasContent() {
    this.element.querySelectorAll('textarea').forEach(textarea => {
      textarea.parentNode.dataset.replicatedValue = textarea.value;
    })
  }
}
