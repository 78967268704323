import ApplicationController from './application_controller'
// import { initChoices } from '../plugins/init-choices'
import Choices from 'choices.js'

/* This is the custom StimulusReflex controller for the Rubric Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static target = ['categoriesForm']
  connect () {
    super.connect()
    // add your code here, if applicable
    this.initChoice()
  }

  initChoice() {
    const el = document.querySelector('select#question_rubric_rubric_id')
    console.log({el})
    this.dropdown = new Choices(el, {
      removeItems: true,
      removeItemButton: true,
      shouldSort: false,
      shouldSortItems: true,
      addItems: true,
      classNames: {
        containerInner: 'choices_custom_inner_single',
        input: 'choices_custom_input_single',
      }
    });
  }

  changeQuestionRubric(e) {
    console.log(e)
    // check if scores attached
    const csrf = this.getCsrf()
    fetch(`/question_rubrics/${e.target.dataset.qrSlug}/check_if_scores_exist`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf
      }
    }).then(data => data.json())
    .then(res => {
      console.log(123, res)
      let warningText = `Are you sure you want to assign a new rubric?`
      if (res.scores_exist) {
        warningText += `\n\nWarning: There are already student essay scores attached to this rubric. Reassigning a new rubric will remove all existing scores.`
      }
      if (confirm(warningText)) {
        this.stimulate('Rubric#select_question_rubric', e.target)
      } else {
        // element.value = e.target.dataset.rubricId
        this.dropdown.setChoiceByValue(e.target.dataset.rubricId)
      }
    })
  }

  afterReflex(element, reflex) {
    // initChoices()
    this.initChoice()
  }
}
