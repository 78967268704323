import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'container' ]
  connect () {
    super.connect()
    // add your code here, if applicable
    console.log("1 connected to katex controller")
    // document.addEventListener("turbolinks:load", event => {

    // console.log(11, this.containerTarget)
    // console.log(22, this.containerTarget.dataset)
    if (this.containerTarget && this.containerTarget.dataset.showKatex === 'true') {
      console.log("2 hello katex?")
      renderMathInElement(document.body, {
        // // customised options
        // // • auto-render specific keys, e.g.:
        delimiters: [
            {left: '$$', right: '$$', display: true},
            {left: '$', right: '$', display: false},
            {left: '\\(', right: '\\)', display: false},
            {left: '\\[', right: '\\]', display: true}
        ],
        // // • rendering keys, e.g.:
        throwOnError : false,
        ignoredClasses : ["no-katex"]
      });
    }
  }
}
