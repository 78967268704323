import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the AnswerKey Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    console.log('answer-keyn controller')
    // add your code here, if applicable
  }

  onChange(e) {
    // console.log('onchange e', e.target.value)
    // console.log('data', e.target.dataset)
    let { isMcq } = e.target.dataset;
    isMcq = isMcq == 'true'
    console.log('isMcq', isMcq)
    if (isMcq) {
      e.target.value = e.target.value ? e.target.value[0]?.toUpperCase() : ''
    }
  }
}
