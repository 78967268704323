import ApplicationController from './application_controller'
import Sortable from 'sortablejs'
import { initChoices } from '../plugins/init-choices'
import Swal from 'sweetalert2'

/* This is the custom StimulusReflex controller for the Courses Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static values = { activeLessonId: Number }
  static targets = [ 'courseForm', 'passagesBox', 'lessonGroup', 'lessonPassageGroup', 'lessonChevron', 'activeLessonContainer', 'courseFilter', 'testTypeFilter', 'tagFilter', 'activeBuild', 'inactiveBuild' ]
  connect () {
    super.connect()
    console.log('values?', this.activeLessonIdValue)
    if (this.hasLessonGroupTarget) {
      this.initLessonGroupSortable();
    }
    if (this.hasLessonPassageGroup) {
      this.initLessonPassageGroupSortable();
    }
    if (this.hasCourseFormTarget) {
      this.listenToFormSubmit()
    }
  }

  activateBuildItems() {
    console.log('activeLessonIdValue?', this.activeLessonIdValue)
    // console.log('activeLessonIdValue type?', typeof this.activeLessonIdValue) // => Number
    // console.log("activeBuildTargets", this.activeBuildTargets)
    // console.log("inactiveBuildTargets", this.inactiveBuildTargets)
    if (this.activeLessonIdValue >= 0) {
      // console.log("There's active lesson id")
      this.activeBuildTargets.forEach((target) => target.classList.remove('hidden'))
      this.inactiveBuildTargets.forEach((target) => target.classList.add('hidden'))
    }
    if (this.activeLessonIdValue == 0) {
      // console.log("There's active lesson id")
      this.inactiveBuildTargets.forEach((target) => target.classList.remove('hidden'))
      this.activeBuildTargets.forEach((target) => target.classList.add('hidden'))
    }
  }

  activeBuildTargetConnected(el) {
    // when the activeBuild targets are inserted into DOM
    // console.log(el)
    if (this.activeLessonIdValue >= 0) el.classList.remove('hidden')
    if (this.activeLessonIdValue == 0) el.classList.add('hidden')
  }

  inactiveBuildTargetConnected(el) {
    // when the activeBuild targets are inserted into DOM
    if (this.activeLessonIdValue >= 0) el.classList.add('hidden')
    if (this.activeLessonIdValue == 0) el.classList.remove('hidden')
  }

  listenToFormSubmit() {
    console.log('form', this.courseFormTarget)
    this.courseFormTarget.addEventListener('submit', (e) => {
      console.log('form submitted')
      this.loading();
    })
  }

  filterTags(e) {
    console.log(222, this.tagFilterTarget.value)
    const target = this.tagFilterTarget
    this.stimulate('Courses#filter_tags', target)
  }

  filter(e) {
    if (e.target.dataset.field == 'test_type') {
      const target = this.testTypeFilterTarget
      console.log(target)
      this.stimulate('Courses#filter_test_type', target)
    } else {
      let targets = this.courseFilterTargets

      console.log(111, targets.length)
      targets = targets.filter(target => target.checked).map(target => target.value)
      console.log({targets})
      // this.loading()
      this.stimulate('Courses#filter', e.target, targets)
    }
  }

  initLessonGroupSortable() {
    this.sortable = Sortable.create(this.lessonGroupTarget, {
      handle: '.three-dots',
      animation: 150,
      group: 'lesson',
      // ghostClass: 'sortable-ghost',
      // chosenClass: 'sortable-chosen',
      onEnd: this.endSort.bind(this)
    })
  }

  expandLesson(e) {
    console.log('activeLessonIdValue', this.activeLessonIdValue)
    // console.log(e.target) // img tag

    // "collapse" the other chevrons first
    this.lessonChevronTargets.filter(el => el != e.target).forEach((el) => el.classList.remove('rotate-90'))

    // "activate/deactivate" current target
    const expand = !e.target.classList.contains('rotate-90')
    if (expand) e.target.classList.add('rotate-90')
    else e.target.classList.remove('rotate-90')

    // map and filter all the other IDS
    const activeLessonContainerIds = this.activeLessonContainerTargets.map(el => el.id)
    // console.log('other ids', this.activeLessonContainerTargets.map(el => el.id))

    this.stimulate('Courses#activate_lesson', e.target, activeLessonContainerIds, expand).then(res => {
      if (expand) this.activeLessonIdValue = e.target.dataset.lessonId
        else this.activeLessonIdValue = 0
      console.log('activeLessonIdValue', this.activeLessonIdValue)
      this.activateBuildItems()
      this.initLessonPassageGroupSortable()
    })
  }

  expandTest(e) {
    // console.log(e.target)
    // const courseMode = this.data.get('mode')
    // console.log('courseMode', courseMode)
    // console.log('activeLessonIdValue', this.activeLessonIdValue)
    const expand = !e.target.classList.contains('rotate-90')
    e.target.classList.toggle('rotate-90')
    this.stimulate('Courses#activate', e.target, expand).then(res => {
      // this.initLessonPassageGroupSortable()
      // this.activateBuildItems() // This will be handled by the (in)activeBuildTargetConnected stimulus callback
    })
  }

  initLessonPassageGroupSortable() {
    console.log(111, this.lessonPassageGroupTargets)
    // console.log(this.lessonPassageGroupTarget.dataset.lessonId)
    this.lessonPassageGroupTargets.forEach(el => {
      Sortable.create(el, {
        handle: '.three-dots',
        animation: 150,
        group: 'nested',
        // ghostClass: 'sortable-ghost',
        // chosenClass: 'sortable-chosen',
        fallbackOnBody: true,
        invertSwap: true,
        // swapThreshold: 0.65,
        onEnd: this.endSortPassage.bind(this)
      })
    })
  }

  endSortPassage(e) {
    console.log(111, e.item.dataset)
    this.stimulate('Courses#sort_lesson_passages', e.item, e.item.dataset.lpId, e.newIndex + 1)
  }

  endSort(e) {
    console.log(0, e.item)
    this.stimulate('Courses#sort_lessons', e.item, e.item.dataset.lessonId, e.newIndex + 1)
  }

  ///// SECTION MODE /////
  addToLessonSectionMode(e) {
    console.log(1111, e.target.dataset)
    this.stimulate('Courses#add_to_lesson_section_mode', e.target)
  }

  ///// SECTION MODE /////

  addToLesson(e) {
    console.log(1111, e.target.dataset)
    const { source, sourceId } = e.target.dataset
    this.stimulate('Courses#add_to_lesson', e.target, source, sourceId, this.activeLessonIdValue).then(res => {

    })
  }

  addTestToCourse(e) {
    console.log(e.target.dataset)
    // const testId = { e.target.dataset }
    const chevronEl = document.getElementById(`test-chevron-el-${e.target.dataset.testId}`)
    const testExpanded = chevronEl ? chevronEl.classList.contains('rotate-90') : false
    if (confirm("Add whole test to this course?")) {
      this.stimulate("Courses#add_test_to_course", e.target, testExpanded)
    }
  }

  async removeFromLesson(e) {
    console.log(1111, e.target.dataset)
    // fetch backend, check if responses exist
    let el;
    let testExpanded = false;
    if (e.target.dataset.testId) {
      el = document.getElementById(`test-chevron-el-${e.target.dataset.testId}`)
      console.log('element found?', el)
      testExpanded = el?.classList?.contains('rotate-90')
      console.log('testExpanded', testExpanded)
    }
    const { source, sourceId } = e.target.dataset

    let responsesExist = await this._tryDestroyLesson(source, sourceId)
    console.log('responsesExist', responsesExist)
    if (responsesExist) {
      Swal.fire({
        title: 'Are you sure?',
        text: `There are already student responses in this lesson. Are you sure you want to remove this from the lesson?\n\nThis will delete all the responses.`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#57A773',
        cancelButtonColor: '#D64933',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          this.stimulate('Courses#remove_from_lesson', e.target, source, sourceId, testExpanded)
        }
      })
    } else {
      if (confirm('Remove from course?')) {
        this.stimulate('Courses#remove_from_lesson', e.target, source, sourceId, testExpanded)
      }
    }
  }

  _tryDestroyLesson(source, sourceId) {
    return new Promise((resolve, reject) => {
      if (source == 'lesson') {
        fetch(`/lessons/${sourceId}/try_destroy`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': this.getCsrf()
          }
        }).then(res => res.json()).then(data => {
          console.log('data', data)
          resolve(data.responses_exist)
        })
      } else {
        resolve(false)
      }
    })
  }

  beforeReflex(element, reflex) {
    if (!element.dataset.loading || element.dataset.loading !== 'false') {
      this.loading()
    }
  }

  // afterSwitchField(element, reflex) {
  //   initChoices()
  // }

  afterReflex(element, reflex) {
    initChoices()
    if (this.hasLessonGroupTarget) this.initLessonGroupSortable()
    if (this.hasLessonPassageGroup) this.initLessonPassageGroupSortable();
    // if (!element.dataset.loading || element.dataset.loading !== 'false') {
      this.loading('hide')
    // }
  }

  // closeOverlay() {
  //   console.log(17238289)
  //   super.closeOverlay()
  // }

  toggleMyCourses(e) {
    console.log(123, e.currentTarget.dataset)
    let href = e.currentTarget.dataset.currentPath;
    // let href = this.hiddenLinkTarget.href;
    const baseUrl = href.split('?')[0]
    let params = href.split('?')[1]
    let paramsObject = {}
    if (params) {
      params.split('&').forEach(param => {
        paramsObject[param.split('=')[0]] = param.split('=')[1]
      })
    }
    // paramsObject['timezone'] = e.detail.value;
    paramsObject['show'] = !paramsObject['show'] ? 'all' : (paramsObject['show'] == 'all' ? 'mine' : 'all')
    const paramsString = Object.keys(paramsObject).map(key => `${key}=${paramsObject[key]}`).join('&');
    // console.log({ paramsString })

    href = `${baseUrl}?${paramsString}`
    console.log('new href', href);
    // this.hiddenLinkTarget.href = href;
    // this.hiddenLinkTarget.click();
    window.location.href = href;
  }
}
