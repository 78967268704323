import ApplicationController from './application_controller'
import Choices from 'choices.js'

export default class extends ApplicationController {
  static values = {
    type: String,
    warnOnRemove: { type: Boolean, default: false },
    extraWarningMsg: { type: String, default: '' },
  }
  connect() {
    super.connect()
    // console.log('inside choicesjs_controller, connect()')
    // console.log('this.extraWarningMsgValue', this.extraWarningMsgValue)
    this.initChoices()
  }

  initChoices() {
    this.choices = new Choices(this.element, this._getInitOptions())
    this._warnOnRemove()
  }

  _getInitOptions() {
    if (this.hasTypeValue) {
      // console.log('this.typeValue', this.typeValue)
      switch(this.typeValue) {
        case 'single':
          return this._singleElementsOptions()
        case 'single-xs':
          return this._singleXsElementsOptions()
        case 'multiple':
          return this._multipleElementsOptions()
        default:
          return this._singleElementsOptions()
      }
    } else {
      return this._singleElementsOptions()
    }
  }

  _singleElementsOptions() {
    return {
      removeItems: true,
      removeItemButton: true,
      shouldSort: false,
      shouldSortItems: true,
      addItems: true,
      classNames: {
        containerInner: 'choices_custom_inner_single',
        input: 'choices_custom_input_single',
      }
    }
  }

  _singleXsElementsOptions() {
    return {
      removeItems: true,
      removeItemButton: true,
      shouldSort: false,
      shouldSortItems: true,
      addItems: true,
      itemSelectText: '',
      classNames: {
        containerInner: 'choices_custom_inner_single_xs',
        input: 'choices_custom_input_single',
      }
    }
  }
  
  _multipleElementsOptions() {
    return {
      removeItems: true,
      removeItemButton: true,
      shouldSort: false,
      shouldSortItems: true,
      addItems: true,
      classNames: {
        containerInner: 'choices_custom_inner',
        input: 'choices_custom_input',
      }
    }
  }

  _warnOnRemove() {
    const choices = this.choices
    let extraMsg = ''
    if (this.hasExtraWarningMsgValue) extraMsg = this.extraWarningMsgValue;
    if (this.hasWarnOnRemoveValue && this.warnOnRemoveValue) {
      choices.passedElement.element.addEventListener('removeItem', function(e) {
        e.stopImmediatePropagation()
        e.stopPropagation()
        // console.log('removeItem event', e.detail)
        // console.log('choices', choices)
        let msg = `Are you sure you want to remove ${e.detail.label}?`;
        msg += `\n\n${extraMsg}`; 
        const userConfirmed = confirm(msg);
        if (!userConfirmed) {
          // Re-add the item if the user did not confirm
          choices.setChoiceByValue(e.detail.value);
        }
      })
      
    }
  }
}