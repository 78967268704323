// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "top", "bottom" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    console.log('target?', this.bottomTarget)
  }

  showBottom(e) {
    // console.log(e)
    console.log('target?', this.bottomTarget)
    this.bottomTarget.classList.remove('hidden')
    this.topTarget.classList.add('hidden')
  }

  showTop(e) {
    // console.log(e)
    // console.log('target?', this.bottomTarget)
    this.bottomTarget.classList.add('hidden')
    this.topTarget.classList.remove('hidden')
  }
}
