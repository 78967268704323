import ApplicationController from './application_controller'
import { initChoices } from '../plugins/init-choices'

/* This is the custom StimulusReflex controller for the UserActivation Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  inputEmail(e) {
    // console.log(e.detail.originalEvent.key)
    const key = e.detail.originalEvent.key
    if (key == 'Enter') {
      this.stimulate('UserActivation#search_email', e.target)
    } else {
      this.stimulate('UserActivation#input_email', e.target)
    }
  }

  afterReflex(element, reflex, noop, reflexId) {
    initChoices()
  }
}
