import ApplicationController from './application_controller'
import { initChoices } from '../plugins/init-choices'

/* This is the custom StimulusReflex controller for the Filter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['tags', 'testType', 'sectionType', 'searchModel', 'search', 'searchApplyButton']
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  search(e) {
    e.preventDefault()
    e.stopPropagation()
    const models = this.searchModelTargets.filter(target => target.checked).map(target => target.value)
    const key = e.detail.originalEvent.key
    const value = this.searchTarget.value
    if (key == 'Enter') {
      this.loading()
      if (this.hasSearchApplyButtonTarget) {
        // console.log('button found')
        this.searchApplyButtonTarget.click();
      } else {
        this.stimulate('Filter#search_focus', e.target).then(res => {
          this.searchApplyButtonTarget.click();
        })
      }
      // this.stimulate('Filter#search', e.target, models, value)
    }
  }

  filterSearch(e) {
    // console.log('search value:', this.searchTarget.value)
    let targets = this.searchModelTargets
    targets = targets.filter(target => target.checked).map(target => target.value)
    this.stimulate('Filter#search', e.target, targets, this.searchTarget.value)
  }

  focusSearch(e) {
    this.stimulate('Filter#search_focus', e.target)
  }

  // clearSearch(e) {
  //   console.log(e)
  //   // do something
  // }

  filterTags(e) {
    const target = this.tagsTarget
    // console.log({target})
    this.loading()
    this.stimulate('Filter#filter_tags', target).then(res => {
      initChoices()
    })
  }

  filter(e) {
    // console.log(e.target.dataset)
    const { key, filters } = e.target.dataset
    // console.log({ filters })
    if (key == 'test_type') {
      // console.log(this.testTypeTarget)
      const target = this.testTypeTarget
      // console.log('target', target)
      this.loading()
      this.stimulate('Filter#filter_test_type', target)
    } else if (key == 'section_type') {
      const target = this.sectionTypeTarget
      // console.log('target', target)
      this.loading()
      // this.stimulate('Filter#filter', e.target, targets)
      this.stimulate('Filter#filter', target)
    }
  }

  afterReflex(element, reflex) {
    // initChoices()
    this.loading('hide')
  }

  afterSwitchField(element, reflex) {
    initChoices()
    // console.log(222)
  }

  afterFilter(element, reflex) {
    initChoices()
    // console.log('afterFilter')
    // this.collapse()
  }

  afterSearch(element, reflex) {
    // console.log('afterSearch')
    this.collapse()
  }

  afterFilterTags(element, reflex) {
    // console.log('afterFilter')
    this.collapse()
  }

  afterClearSearch(element, reflex) {
    // console.log('afterClearSearch')
    this.collapse()
  }

  afterExpand(element, reflex) {
    initChoices()
  }

  afterSetTagOption(element, reflex) {
    initChoices()
  }

  collapse() {
    // console.log('collapsing')
    if (this.hasSearchTarget) {
      // console.log('target search', this.searchTarget)
      this.searchTarget.blur()
    }
    document.getElementById('filter-expandible').innerHTML = ""
  }
}
