import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Upload Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'input', 'inputAnswerKey', 'downloadAnswerKey' ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  trigger(e) {
    console.log(e.currentTarget)
    console.log(this.inputTarget)
    this.inputTarget.click()
  }

  triggerUploadAnswerKey(e) {
    // console.log(11, e.currentTarget.nextElementSibling)
    const form = e.currentTarget.nextElementSibling;
    const fileInput = form.querySelector('input[type="file"]');
    fileInput.click();
  }

  async uploadAnswerKey(e) {
    console.log('HEELLLOOOOO')
    const { dataset } = e.currentTarget
    const submit = e.target.parentNode.querySelector('input[type="submit"]')
    // console.log(1233, submit)
    const formData = new FormData
    formData.append('file', e.currentTarget.files[0])
    const canUpload = await this.checkIfAnswerKeyComplete(dataset.testId, formData)
    console.log({ canUpload })
    if (!canUpload) {
      alert(`This test has been published and some answer keys are missing, please re-check your file.`)
      window.location.reload()
    } else {
      if (confirm("This will override the current answer keys. Are you sure you want to continue?")) {
        submit.click()
      } else {
        window.location.reload()
      }
    }
  }

  checkIfAnswerKeyComplete(testId, formData) {
    const headers = this.getCsrfHeaders();
    return new Promise((resolve, reject) => {
      fetch(`/tests/${testId}/check_if_answer_key_can_be_overriden`, {
        headers,
        body: formData,
        method: 'post'
      }).then(response => response.json()).then(res => resolve(res))
    })
  }

  // async uploadAnswerKey(e) {
  //   this.loading()
  //   console.log(e)
  //   const input = e.target
  //   if ('files' in input && input.files.length > 0) {
  //     let content = await this.readFileContent(input.files[0])

  //     this.stimulate('Upload#parse_answer_key', e.target, content).then(res => {
  //       this.loading('hide')
  //       alert('Answer Key uploaded')
  //     })
  //   }
  // }

  async upload (e) {
    // console.log('target', e.target)
    this.loading()
    const input = e.target
    if ('files' in input && input.files.length > 0) {
      console.log(input.files[0])
      let html = await this.readFileContent(input.files[0])
      // read = read.split('<body>')[1].split('</body>')[0]
      // console.log('read the file:', html)
      this.stimulate('Upload#parse', e.target, html).then(res => {
        console.log('finish uploading')
        this.loading('hide')
        // window.location.reload(true)
      })
    }
  }

  readFileContent(file) {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result)
      reader.onerror = error => reject(error)
      reader.readAsText(file)
    })
  }

}
