import ApplicationController from './application_controller'
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';

export default class extends ApplicationController {
  static targets = ["mainForm", "uploadForm", "uploadDefaultForm"]
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  triggerUpload(e) {
    // console.log(10, e.currentTarget.nextElementSibling)
    this.loading()
    const form = e.currentTarget.nextElementSibling;
    const fileInput = form.querySelector('input[type="file"]');
    fileInput.click();
  }

  async previewUpload(e) {
    this.loading('hide')
    const file = e.currentTarget.files[0]
    // console.log(e.currentTarget.dataset)
    const { testId } = e.currentTarget.dataset
    let xlsx = await this.readFileContent(file)
    // console.log(Object.keys(xlsx.Sheets))
    const worksheets = Object.keys(xlsx.Sheets)
    const list = [ 'Scoring Scale', 'Score Conversions Table' ];
    const inSheetList = (x) => list.includes(x);
    if (worksheets.length >= 1 && worksheets.some(inSheetList)) {
      const worksheet = xlsx.Sheets[worksheets.includes('Score Conversions Table') ? 'Score Conversions Table' : 'Scoring Scale'];
      const data = XLSX.utils.sheet_to_json(worksheet)
      // console.log('uploaded data', data)
      this.stimulate('ScoreConversions#preview_upload', testId, data)
    } else {
      alert(`Worksheet cannot be found. Make sure you have a worksheet named 'Score Conversions Table'. Or you can download the score XLSX file and use it as a template.`)
    }
  }

  upload(e) {
    // console.log(123, this.uploadFormTarget)
    // const { dataset } = e.currentTarget
    // const submit = e.target.parentNode.querySelector('input[type="submit"]')
    const submit = this.uploadFormTarget.querySelector('input[type="submit"]')
    // console.log(1233, submit)
    // const formData = new FormData
    // formData.append('file', e.currentTarget.files[0])
    if (confirm("This will override the current score conversions table. Are you sure you want to continue?")) {
      this.loading()
      submit.click()
    } else {
      // window.location.reload()
      this.loading('hide')
    }
  }

  cancelUpload() {
    this.uploadFormTarget.reset()
    this.stimulate('ScoreConversions#close_preview')
    // const fileInput = this.uploadFormTarget.querySelector('input[type="file"]')
    // console.log(111, fileInput)
  }

  uploadDefault() {
    this.uploadDefaultFormTarget.querySelector('input[type="submit"]')?.click()
  }

  submitForm(e) {
    this.mainFormTarget.submit()
  }

  preview(e) {
    // TODO -> how to do a preview here?
    // Should we popup a new window of previews?
    // Should I go into reflex?
    this.loading()
    e.stopPropagation()
    e.preventDefault()
    const { form } = e.target;
    const select = form.querySelector('select');
    const testId = select.value;
    if (testId) {
      this.stimulate('ScoreConversions#preview', testId).then(res => this.loading('hide'))
    }
  }

  closePreview() {
    this.stimulate('ScoreConversions#close_preview')
  }

  applyOtherTable(e) {
    const { currentTestName, previewedTestName, currentTestId, previewedTestId } = e.target.dataset
    if (confirm(`Are you sure you want to apply ${previewedTestName}'s score table to ${currentTestName}? This will overwrite the current score table.`)) {
      // console.log('yes')
      this.loading();
      this.stimulate('ScoreConversions#apply_from_previewed_test', currentTestId, previewedTestId).then(res => {
        // console.log('finished')
        this.loading('hide')
      })
    }
  }

  readFileContent(file) {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = event => {
        const result = XLSX.read(event.target.result, {type: 'binary'});
        resolve(result)
      }
      reader.onerror = error => reject(error)
      // reader.readAsText(file)
      reader.readAsBinaryString(file);
    })
  }

  // afterReflex(element, reflex) {
  //   console.log('finished')
  //   // initChoices()

  //   this.loading('hide')
  // }
}
