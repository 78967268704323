// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

// Trix is moved to separate bundle pack, and imported only on the form that requires it
// This is to make js entry file more lightweight
// require("trix")
// require("@rails/actiontext")
// require("packs/custom_trix")

import "stylesheets/application.scss";
// import "@fortawesome/fontawesome-free/css/all";

import { initChoices } from '../plugins/init-choices'

import debounced from 'debounced'
debounced.initialize({ ...debounced.events, input: { wait: 10000 } })

import Swal from 'sweetalert2'
window.Swal = Swal;

document.addEventListener("turbolinks:load", () => {
  initChoices();
})

