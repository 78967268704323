import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']
  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    // console.log(this.containerTarget)
    if (this.containerTarget) {
      setTimeout(() => {
        this.containerTarget.remove()
      }, 5000)
    }
  }

  close(e) {
    if (this.containerTarget) {
      this.containerTarget.remove()
    }
  }
}
