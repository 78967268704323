import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'frqInputError', 'mathFrqPreview', 'autoSaveIndicator' ]
  connect () {
    super.connect()
    // add your code here, if applicable
  }
  preventPaste(e) {
    // console.log('preventPaste')
    e.preventDefault()
  }

  validateKeystrokes(e) {
    // beforeinput event
    // console.log(e.data)
    const { value } = e.currentTarget;
    let key = e.data // this is the item about to be inputted
    if (key == null) return;
    if (/[^0-9.\-\/]/.test(key)) {
      // return "Invalid characters";
      e.preventDefault()
      return false;
    } else {
      const isNegative = value.startsWith("-");
      const maxLength = isNegative ? 6 : 5;

      if (value.length + 1 <= maxLength) {
        this._displayError("");
        return true;
      } else if (!isNegative && key === "-" && value.length === 5) {
        // Allow adding a minus sign to convert to a negative value
        e.currentTarget.value = "-" + value;
        this._displayError("");
        e.preventDefault();
        return false;
      } else {
        // Too long
        e.preventDefault();
        return false;
      }
    }
  }

  _displayError(message) {
    if (this.hasFrqInputErrorTarget) {
      this.frqInputErrorTarget.innerText = message;
    }
  }

  _validateArrangement(value) {
    // Original Ruby Regex:
    // /\A-?(\d*(\.\d*)?(\/\d*)?)?\z/
    const regex = /^-?(\d*(\.\d*)?(\/\d*)?)?$/;
    if (regex.test(value) && (value.match(/-/g) || []).length <= 1 && (value.match(/\//g) || []).length <= 1 && !value.startsWith('/')) {
      this.convertToLatex(value);
      // return "valid";
      this._displayError("");
      // console.log('valid', value)
      return true;
    } else {
      this._displayError("❗ You've entered a decimal, slash, or minus sign in the wrong place.");
      return false;
    }
  }

  convertToLatex(answer) {
    // console.log('--------------- in convertToLatext, answer:', answer)
    let latexAnswer = answer;
    if (answer.includes('/')) {
      const parts = answer.split('/');
      latexAnswer = parts[0][0] === '-' ? `$-\\cfrac{${parts[0].slice(1)}}{${parts[1]}}$` : `$\\cfrac{${parts[0]}}{${parts[1]}}$`;
    } else {
      latexAnswer = `$${answer}$`;
    }
    // console.log('latexAnswer', latexAnswer)
    if (this.hasMathFrqPreviewTarget) {
      if (answer == '') {
        this.mathFrqPreviewTarget.innerHTML = '';
        return;
      }
      this.mathFrqPreviewTarget.innerHTML = latexAnswer;
      this.renderKatex()
    }
  }

  renderKatex() {
    // console.log('IN RENDER KATEX')
    // const container = this.containerTarget
    // console.log(container?.dataset)
    if (this.hasContainerTarget && this.containerTarget.dataset.showKatex == 'true') {
      // console.log('initialize katex in exams/lessons controller')
      this.renderKatexInBody()
    }
  }

  triggerAutoSaveEssay(e) {
    const target = e.currentTarget
    console.log('triggerAutoSaveEssay', target.dataset)
    this.autoSaveInterval = setInterval(() => {
      this.stimulate(`${target.dataset.reflexClass}#answer_essay`, target)
      console.log('auto save essay every 30 seconds')
      this._displayAutoSaveIndicator()
    }, 30000)
  }

  _displayAutoSaveIndicator() {
    if (this.hasAutoSaveIndicatorTarget) {
      this.autoSaveIndicatorTarget.classList.remove('hidden')
    }
    setTimeout(() => {
      if (this.hasAutoSaveIndicatorTarget) {
        this.autoSaveIndicatorTarget.classList.add('hidden')
      }
    }, 5000)
  }

  disconnect() {
    // clear interval
    clearInterval(this.autoSaveInterval)
    console.log('----------- disconnect, clearing interval')
    super.disconnect()
    // remove your code here, if applicable
  }
}
