import ApplicationController from './application_controller'
import { createConsumer } from "@rails/actioncable"

/* This is the custom StimulusReflex controller for the MapPopup Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static values = {
    userId: Number,
    isStudent: Boolean,
  }
  connect () {
    super.connect()
    // ABANDONED IN THIS VERSION
    // Use JS heartbeat directly to student_timer_channel
    
    // add your code here, if applicable
    // console.log('connected to online controller -->', this.element)
    // console.log('userId -->', this.userIdValue)
    // console.log('isStudent -->', this.isStudentValue)
    // if (
    //   this.hasUserIdValue && 
    //   this.userIdValue &&
    //   this.hasIsStudentValue &&
    //   this.isStudentValue
    // ) this._subscribeToChannel()
  }

  _subscribeToChannel() {
    this.channel = createConsumer().subscriptions.create({ channel: "OnlineChannel", user_id: this.userIdValue }, {
      connected: () => {
        console.log('student connected to OnlineChannel')
      },
      disconnected: () => {
        console.log('disconnected from OnlineChannel')
      },
      received: (data) => {
        console.log('received data from OnlineChannel', data)
      }
    })
  }
}
