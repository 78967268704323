import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Eliminate Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    // console.log("connected to eliminate")
    super.connect()
  }
}
