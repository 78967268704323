import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  afterReflex(element, reflex) {
    window.scrollTo({
  top: 0,
  left: 0,
  behavior: "smooth",
});
  }
}
