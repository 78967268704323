import ApplicationController from './application_controller'
import Sortable from 'sortablejs'
import Swal from 'sweetalert2'

/* This is the custom StimulusReflex controller for the Tests Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['chevronIcon', 'sectionGroup', 'updateAnswerKey', 'save']

  connect () {
    super.connect()
    // add your code here, if applicable
    if (this.hasSectionGroupTarget) {
      console.log('this.sectionGroupTarget')
      this.sortable = Sortable.create(this.sectionGroupTarget, {
        handle: '.three-dots',
        animation: 150,
        group: 'section',
        // ghostClass: 'sortable-ghost',
        // chosenClass: 'sortable-chosen',
        onEnd: this.endSort.bind(this)
      })
    }
  }

  endSort(e) {
    // console.log(e)
    console.log(e.item)
    this.stimulate('Tests#sort_section', e.item.dataset.id, e.newIndex + 1)
  }

  editSection(e) {
    // console.log(e.currentTarget.dataset)
    document.getElementById(e.currentTarget.dataset.elementId).classList.toggle('hidden')
    if (this.hasChevronIconTarget) {
      if ([...this.chevronIconTarget.classList].includes('rotate-90')) {
        this.chevronIconTarget.classList.remove('rotate-90')
        this.chevronIconTarget.classList.add('rotate-180')
      } else{
        this.chevronIconTarget.classList.add('rotate-90')
        this.chevronIconTarget.classList.remove('rotate-180')
      }
    }
  }

  removeSection(e) {
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    // console.log(e.currentTarget.dataset)
    // console.log('target', e.target)
    fetch(`/sections/${e.target.dataset.id}/try_delete`,
      {headers: {"X-CSRF-TOKEN": csrf}})
    .then(res => res.json())
    .then(res => {
      console.log(111, res)
      Swal.fire({
        title: "Are you sure you want to delete this section?",
        text: `This section has ${res.passages_count} passages, ${res.questions_count} questions.`,
        icon: 'warning',
        confirmButtonText: 'DELETE',
        confirmButtonColor: '#D64933',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#57A773',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log('yes')
          this.stimulate('Tests#remove_section', e.target)
        } else {
          console.log('cancelled')
          // Swal.fire('Changes are not saved', '', 'info')
          // this.submitSection(e, dataset.studentExamSectionId, dataset.lastSection == 'true', dataset.examSlug)
        }
      })
    })
  }

  // toggleSectionSorter(e) {
  //   // Moved to reflex directly
  //   console.log(e.currentTarget.dataset)
  //   const els = document.querySelectorAll(`.section-sorter-${e.currentTarget.dataset.sectionId}`)
  //   if (els.length) {
  //     els.forEach(el => {
  //       el.classList.toggle('hidden')
  //     })
  //   }
  // }
  beforeToggleSorting(element, reflex) {
    this.loading()
  }

  afterToggleSorting(element, reflex) {
    this.loading('hide')
  }
}
