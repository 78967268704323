import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['avatarInput', 'studentDropdownNav']
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  triggerAvatarInput(e) {
    console.log(this.avatarInputTarget)
    this.avatarInputTarget.click()
  }

  uploadAvatar(e) {
    this.loading()
    // console.log(e.currentTarget.dataset)
    const target = e.currentTarget
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    // console.log('files', target.files)

    let formData = new FormData()
    const file = target.files[0]
    // console.log('size', file.size)

    if (file.size/1024/1024 > 2) {
      alert("File size too big. Max. 2 MB.")
      this.loading('hide')
    } else {
      let formData = new FormData();
      formData.append('avatar', file)

      // console.log({formData})

      const url = `/users/upload_avatar`

      fetch(url, {
        method: 'post',
        body: formData,
        headers: {"X-CSRF-TOKEN": csrf}
      }).then(response => response.json()).then(res => {
        // console.log('triggering display_avatar', res)
        this.stimulate('Users#display_avatar', target).then(res => this.loading('hide'))
      })
    }
  }

  toggleStudentNav() {
    if (this.studentDropdownNavTarget) {
      this.studentDropdownNavTarget.classList.toggle('hidden')
    }
  }
}
