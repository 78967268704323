import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Bookshelves Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "hoverDiv", "plusBtn", "selectCheckbox", "showSearchFormSubmitBtn", "showSearchHint" ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  showHoverState(e) {
    // console.log(this.plusBtnTarget)
    if (this.hasHoverDivTarget) {
      this.hoverDivTarget.classList.remove('hidden')
    }
    if (this.hasPlusBtnTarget) {
      this.plusBtnTarget.classList.add('scale-150')
    }
  }

  hideHoverState(e) {
    // console.log(this.hoverDivTargets)
    if (this.hasHoverDivTarget) {
      this.hoverDivTarget.classList.add('hidden')
    }
    if (this.hasPlusBtnTarget) {
      this.plusBtnTarget.classList.remove('scale-150')
    }
  }

  select(e) {
    // console.log(e.currentTarget)
    const data = e.currentTarget.dataset

    if (data.goto == 'true') {
      window.location.href = data.path
    } else {
      // Mark only this checkbox as checked
      const checkboxes = document.querySelectorAll("[data-bookshelves-target='selectCheckbox']")
      if (this.selectCheckboxTarget.checked) {
        // All unchecked -> hide button
        checkboxes.forEach(cb => cb.checked = false)
        const btn = document.getElementById('bookshelves-next-button')
        btn.href = '#'
        btn.classList.add('hidden')
      } else {
        // 1 bookshelf selected
        checkboxes.forEach(cb => cb.checked = false)
        this.selectCheckboxTarget.checked = true
        // Add href to the a button, and display it
        const btn = document.getElementById('bookshelves-next-button')
        btn.href = data.path
        btn.classList.remove('hidden')
      }
    }
  }

  selectCheckboxTargetConnected(e) {
    console.log(e)
  }

  search(e) {
    // console.log(e.target.value)
    if (e.target.value?.length) {
      this.showSearchHintTarget.classList.remove('hidden');
    } else {
      this.showSearchHintTarget.classList.add('hidden');
    }
    // this.showSearchFormSubmitBtnTarget.click()
    // console.log(e.key)
  }

  clearSearch() {
    this.showSearchHintTarget.classList.add('hidden');
    this.showSearchFormSubmitBtnTarget.click()
  }
}
