import ApplicationController from './application_controller'
import { initChoices } from '../plugins/init-choices'
import Swal from 'sweetalert2'

/* This is the custom StimulusReflex controller for the Clone Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['content', 'bookshelf', 'test', 'section', 'buttons']

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  display(e) {
    console.log(e)
  }

  displayNext(e) {
    console.log(e)
    const { field } = e.target.dataset
    const { value } = e.detail
    if (field == 'bookshelf' && this.hasTestTarget) {
      this.testTarget.classList.remove('hidden')
      this.callDisplayReflex(e)
    } else if (field == 'test' && this.hasSectionTarget) {
      this.sectionTarget.classList.remove('hidden')
      this.callDisplayReflex(e)
    } else if (field == 'section' && this.hasButtonsTarget) {
      console.log('value', value)
      console.log('value', parseInt(value))
      this.callDisplayReflex(e)
      // if (!value || parseInt(value) <= 0) this.buttonsTarget.classList.add('hidden')
      // else this.buttonsTarget.classList.remove('hidden')
    }
  }

  callDisplayReflex(e) {
    this.stimulate('Clone#display_next', e.target).then(res => {
      console.log('hello')
      initChoices()
    })
  }

  displayPublishedWarning(e) {
    Swal.fire({
      title: 'Heads Up!',
      html: "This test's content status is still <span class='text-negative font-semibold'>IN PROGRESS</span>.<br>Are you sure you want to clone it?",
      icon: 'warning',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#D64933',
      showCancelButton: true,
      // reverseButtons: true,
      confirmButtonText: 'Yes, Clone',
      confirmButtonColor: '#57A773',
    }).then(result => {
      if (result.isConfirmed) {
        // console.log("confirmed please clone")
        this.stimulate('Clone#display', e.target)
      }
    })
  }

  beforeClone(element, reflex) {
    if (this.hasContentTarget) {
      // this.buttonsTarget.children.forEach(child => console.log(child))
      [...this.contentTarget.children].forEach(child => child.classList.toggle('hidden'))
    }
  }

  afterDisplay(element, reflex) {
    initChoices()
  }

  afterCreateBookshelf(element, reflex) {
    initChoices()
  }

  afterCreateTest(element, reflex) {
    initChoices()
  }

  afterCreateSection(element, reflex) {
    initChoices()
  }

  afterCancelCreate(element, reflex) {
    initChoices()
  }
}
