import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the StudentResponses Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  expandLegend(e) {
    document.getElementById(`legend-content`).classList.toggle('hidden')
    e.target.classList.toggle('rotate-90')
  }

  expandLesson(e) {
    // console.log(e.target.dataset)
    const data = e.target.dataset
    const group = document.querySelector(`[data-lesson-group-id="${data.lessonId}"]`)
    if (group) {
      group.classList.toggle('hidden')
      e.target.classList.toggle('rotate-90')
    }
  }

  expandLp(e) {
    // console.log(e.target.dataset)
    const data = e.target.dataset
    const group = document.querySelector(`[data-lp-group-id="${data.lpId}"]`)
    if (group) {
      group.classList.toggle('hidden')
      e.target.classList.toggle('rotate-90')
    }
  }
}
