import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Image Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'hoverBox' ]
  connect () {
    super.connect()
    // add your code here, if applicable
    const figures = document.querySelectorAll('figure')
    // console.log(figures.length)
    if (figures.length) {
      this.listenToFigureEvents(figures)
    }
  }

  listenToFigureEvents(figures) {
    figures.forEach(figure => {
      figure.classList.add('cursor-zoom-in')
      figure.addEventListener('click', e => {
        const img = figure.querySelector('img')
        // console.log(img.src)
        // parentNode => <action-text-attachment> that contains the attachment's sgid
        // with sgid we can find the ActiveStorage::Blob instance
        // console.log(figure.parentNode)
        this.stimulate('Image#preview_figure', figure.parentNode, img.src, {h: window.innerHeight, w: window.innerWidth})
      })
    })
  }

  mouseover(e) {
    // console.log('mouseover')
    this.hoverBoxTarget.classList.remove('hidden')
  }

  mouseout(e) {
    // console.log('mouseout')
    this.hoverBoxTarget.classList.add('hidden')
  }

  preview(e) {
    // const body = document.querySelector('body')
    this.stimulate('Image#preview', {h: window.innerHeight, w: window.innerWidth})
  }

  beforeReflex(element, reflex, noop, reflexId) {
    this.loading()
  }

  afterReflex(element, reflex, noop, reflexId) {
    this.loading('hide')
  }
}
