import ApplicationController from './application_controller'
import { createConsumer } from "@rails/actioncable"
// import Swal from 'sweetalert2'

/* This is the custom StimulusReflex controller for the Timer Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'showBtn', 'hideBtn', 'timerDiv', 'timeText', 'secondsInput', 'clockIcon', 'flash', 'noConnection' ]
  static values = {
    time: Number,
    restartTime: Number,
    isExam: Boolean,
    objectId: Number,
    studentId: Number,
  }

  connect () {
    super.connect();
    this.onlineStatus = true;
    this.lastTimerTime = null;
    this.dbTimerTime = null;
    this.restartTimeInSecs = null;
    // No need to use localStorage, everything is stored in db
    // get lastTimerTime from localStorage
    // const lastStoredTimer = localStorage.getItem(this._timerStorageName());
    // this.lastTimerTime = lastStoredTimer || this.timeValue * 60;
    // this.lastTimerTime = this.timeValue * 60;
    // console.log('connected to timer, lastTimerTime -->', this.lastTimerTime)
    // this._checkConnection()

    if (this.hasIsExamValue && this.hasObjectIdValue) this._subscribeToChannel()

    this.currentIntervalRef = null;
    this.flashShown = false;
  }

  _subscribeToChannel() {
    console.log('subscribing to channel')
    this.channel = createConsumer().subscriptions.create({ channel: "StudentTimerChannel", timer_type: this.isExamValue?'exams':'lessons', object_id: this.objectIdValue, student_id: this.studentIdValue }, {
      connected: () => {
        console.log('connected to TimerChannel')
        if (this.hasNoConnectionTarget) {
          this.noConnectionTarget.classList.add('hidden')
        }
        this.pingInterval = setInterval(_ => {
          console.log('pings every 5 seconds')
          this.channel.perform('ping')
        }, 5000)
      },
      disconnected: () => {
        console.log('disconnected from TimerChannel')
        this._pauseTimer()
        clearInterval(this.pingInterval)
      },
      received: (data) => {
        console.log('received data from TimerChannel', data)
        if (data.time) {
          this.lastTimerTime = data.time;
          this.dbTimerTime = data.time; // this one doesn't change as the timer runs
          if (data.restart_time) {
            this.restartTimeInSecs = data.restart_time;
          }
          this._startTimer()
        }
      }
    })
  }

  _startTimer() {
    if (this.lastTimerTime > 0) {
      this.runTimerCountdown()
    } else if (this.restartTimeInSecs) {
      this.runRestartTimer()
    } else if (this.lastTimerTime < 0 && !this.restartTimeInSecs) {
      // console.log('third condition!')
      this.dispatch('showExpire')
    }
  }

  runTimerCountdown() {
    const expire = new Date().getTime() + (this.lastTimerTime * 1000);
    // console.log('expire', expire)
    // let intervalRef = null;
    const timer = this.timeTextTarget;
    const secsEl = this.secondsInputTarget;

    secsEl.addEventListener("change", e => {
      // console.log('secs', e.target.value);
      if (parseInt(e.target.value) <= 300) {
        timer.classList.add('text-negative')
      }
    })

    this.currentIntervalRef = setInterval(_ => {
      let current = new Date();
      let count = expire - current;
      this.lastTimerTime = count / 1000;
      // console.log('count ONE', count);
      let s = Math.floor((count /  1000)) % 60;
      let m = Math.floor((count / 60000)) % 60;

      timer.innerText = `${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;

      // if (count < 11000) {
      if (count < 1000 * 60 * 5) {
        // console.log('below 5 mins?')
        this.showFlash()
        secsEl.value = Math.floor((count /  1000));
        secsEl.dispatchEvent(new Event('change'))
      }

      if (count < 0) {
        this.stopCurrentTimer()
        timer.innerText = "00:00";
        timer.classList.add('text-negative')
        // console.log('this.lastTimerTime', this.lastTimerTime)
        if (this.dbTimerTime > 0) {
          console.log('dispatching showExpire to the other controller')
          this.dispatch('showExpire');
        }
        // if (this.timeValue > 0) this.stimulate(`${this.isExamValue ? 'Exams' : 'Lessons'}#expire_time_limit`).then(res => {
        //   this.dispatch('showExpire')
        // })
      }
    }, 1000);
  }

  showFlash() {
    // if (!this.flashShown) this.flashTarget.classList.remove('hidden')
    if (!this.flashShown) {
      this.flashShown = true;
      this.flashTarget.classList.remove('hidden')
      setTimeout(() => this.hideFlash(), 1000 * 30)
    }
  }

  hideFlash() {
    // console.log('hideFlash', 'isflashShown?', this.flashShown);
    this.flashTarget.classList.add('hidden')
  }

  runRestartTimer() {
    // let intervalRef = null;
    // console.log('this.hasRestartTimeValue', this.hasRestartTimeValue);
    // this.restartTimeInMs = ((this.hasRestartTimeValue ? this.restartTimeValue : 0) * 60 * 1000);
    this.restartTimeInMs = ((this.restartTimeInSecs ? this.restartTimeInSecs : 0) * 1000);
    const timer = this.timeTextTarget;
    timer.classList.add('text-negative');

    this.currentIntervalRef = setInterval(_ => {
      // let current = new Date().getTime();
      this.restartTimeInMs += 1000;
      // console.log('count at restart', count);
      let s = Math.floor((this.restartTimeInMs /  1000)) % 60;
      let m = Math.floor((this.restartTimeInMs / 60000)) % 60;
      let h = Math.floor((this.restartTimeInMs / (60000 * 60)) % 60)
      let days = Math.floor(this.restartTimeInMs / (1000 * 60 * 60 * 24));

      timer.innerText = `- ${days ? days + 'd ' : ''}${h ? h.toString().padStart(2, "0") + ':' : ''}${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;

    }, 1000);
  }

  stopCurrentTimer() {
    console.log('stop current timer')
    clearInterval(this.currentIntervalRef)
  }

  hide() {
    // this.timerDivTarget.classList.add('hidden');
    this.timeTextTarget.classList.add('hidden');
    this.clockIconTarget.classList.remove('hidden');
    this.hideBtnTarget.classList.add('hidden');
    this.showBtnTarget.classList.remove('hidden');
  }

  show() {
    // this.timerDivTarget.classList.remove('hidden');
    this.timeTextTarget.classList.remove('hidden');
    this.clockIconTarget.classList.add('hidden');
    this.hideBtnTarget.classList.remove('hidden');
    this.showBtnTarget.classList.add('hidden');
  }

  async _checkConnection() {
    // const checkOnlineStatus = async () => {
    //   try {
    //     const online = await fetch("/1pixel.png");
    //     // this.onlineStatus = online.status >= 200 && online.status < 300
    //     return online.status >= 200 && online.status < 300; // either true or false
    //   } catch (err) {
    //     // this.onlineStatus = false;
    //     return false; // definitely offline
    //   }
    // };

    const checkActionCableConnection = () => {
      return this.isActionCableConnectionOpen()
    }

    this.checkOnlineStatusInterval = setInterval(async () => {
      // const result = await checkOnlineStatus();
      const result = checkActionCableConnection();
      // console.log('checking connection interval...', result)
      // here it returns false when I turned off wifi
      // check this.onlineStatus, is this a bug?
      // console.log('this.onlineStatus', this.onlineStatus)
      if (!result && this.onlineStatus) {
        // console.log('went offline')
        this.onlineStatus = false;
        this._pauseTimer()
      } else if (result && !this.onlineStatus) {
        // console.log('came online')
        this.onlineStatus = true;
        if (this.lastTimerTime > 0) {
          this._resumeTimerInDB()
        } else if (this.hasRestartTimeValue) {
          this.runRestartTimer()
        } 
      }
    }, 5000);
  }

  async _resumeTimerInDB() {
    if (this.isActionCableConnectionOpen()) {
      // clear storage
      // const timerTime = localStorage.getItem(this._timerStorageName()) || this.lastTimerTime;
      // const timerTime = this.lastTimerTime;
      
      this.stimulate('Timer#resume', this.isExamValue, this.objectIdValue).then(res => {
        // if (this._timerStorageName()) localStorage.removeItem(this._timerStorageName());
        this.runTimerCountdown()
        if (this.hasNoConnectionTarget) this.noConnectionTarget.classList.add('hidden')
      })
    } else {
      console.log('connection not open, reopen:')
      createConsumer().connection.open()
      setTimeout(() => {
        this._resumeTimerInDB()
      }, 2000)
    }
  }

  async _pauseTimer() {
    this.stopCurrentTimer()
    if (this.hasNoConnectionTarget) this.noConnectionTarget.classList.remove('hidden')
    // console.log('pause timer in db')
    // save to localStorage because of connection failure
    // if (this._timerStorageName()) localStorage.setItem(this._timerStorageName(), this.lastTimerTime);

    // Prob no need to bother with trying the reflex, there's no internet
    // try {
    //   await this.stimulate('Timer#pause', this.isExamValue, this.objectIdValue, this.lastTimerTime)
    // } catch (error) {
    //   console.error('error', error)
    //   // add logic on timer resume, if error, creates the 'paused' timer log
    // }
  }

  _timerStorageName() {
    return this.hasIsExamValue && this.hasObjectIdValue ? `${this.isExamValue?'exam':'lesson'}-${this.objectIdValue}-lastTimerTime` : null;
  }

  disconnect() {
    console.log('disconnected')
    // clearInterval(this.checkOnlineStatusInterval)
    // Do not unsubscribe, channel will automaticall be unsubscribed with online_channel connection detect disconnection
    // Which means timer does not get paused if student merely navigates out of the lesson or exam page.
    this.channel.unsubscribe()
  }
}
