import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the ReviewQuestions Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "bookmark"]
  // static values = {
  //   marked: Boolean
  // }

  connect () {
    // console.log("connected to review questions")
    super.connect()
  }

  mark(event) {
    // console.log('hello')
    event.preventDefault()
    // const { marked } = event.currentTarget.dataset;
    this.stimulate('ReviewQuestions#toggle', event.currentTarget)
  }

}
