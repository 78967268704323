// const Choices = require('choices.js')
import Choices from 'choices.js'

const initChoices = () => {
  // const elements = document.querySelectorAll("[data-behavior='choicesjs']");
  const elements = document.querySelectorAll("[data-behavior='choicesjs']");
  const singleElements = document.querySelectorAll("[data-behavior='choicesjs-single']");
  const singleXsElements = document.querySelectorAll("[data-behavior='choicesjs-single-xs']");
  // const element = document.getElementById("job_city_id");
  console.log('inside initChoices')
  if (elements.length) {
    console.log('inside initChoices, elements found!')
    elements.forEach(element=> {
      new Choices(element, {
        removeItems: true,
        removeItemButton: true,
        shouldSort: false,
        shouldSortItems: true,
        addItems: true,
        fuseOptions: {
          includeScore: true,
          includeMatches: true,
        },
        classNames: {
          // containerOuter: 'choices_custom_outer',
          containerInner: 'choices_custom_inner',
          // focusState: 'choices_custom_focus'
          // list: 'choices_custom_list',
          // listItems: 'choices__list--multiple',
          // listSingle: 'choices__list--single',
          input: 'choices_custom_input',
          // placeholderValue: 'Add',
          // item: 'choices_custom_item',
        }
      });
    })
  }
  if (singleElements.length) {
    console.log('inside initChoices, single elements found!')
    singleElements.forEach(element=> {
      new Choices(element, {
        removeItems: true,
        removeItemButton: true,
        shouldSort: false,
        shouldSortItems: true,
        addItems: true,
        classNames: {
          // containerOuter: 'choices_custom_outer',
          containerInner: 'choices_custom_inner_single',
          // focusState: 'choices_custom_focus'
          // list: 'choices_custom_list',
          // listItems: 'choices__list--multiple',
          // listSingle: 'choices__list--single',
          input: 'choices_custom_input_single',
          // placeholderValue: 'Add',
          // item: 'choices_custom_item',
        }
      });
    })
  }
  if (singleXsElements.length) {
    console.log('inside initChoices, single elements found!')
    singleXsElements.forEach(element=> {
      new Choices(element, {
        removeItems: true,
        removeItemButton: true,
        shouldSort: false,
        shouldSortItems: true,
        addItems: true,
        itemSelectText: '',
        classNames: {
          // containerOuter: 'choices_custom_outer',
          containerInner: 'choices_custom_inner_single_xs',
          // focusState: 'choices_custom_focus'
          // list: 'choices_custom_list',
          // listItems: 'choices__list--multiple',
          // listSingle: 'choices__list--single',
          input: 'choices_custom_input_single',
          // placeholderValue: 'Add',
          // item: 'choices_custom_item',
        }
      });
    })
  }
}

export { initChoices }
