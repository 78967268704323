// import ApplicationController from './application_controller'
import AnswerQuestionsController from './answer_questions_controller'
import { initChoices } from '../plugins/init-choices'
import CableReady from 'cable_ready'
import Swal from 'sweetalert2'

export default class extends AnswerQuestionsController {
  static targets = [ 'passage', 'container', 'submitBtn', 'finishTestBtn', 'invisibleButton', 'summaryButtons', 'updateScoresForm', 'scoresSummaryTable', 'editScoresButton' ]
  static values = {
    showPassage: Boolean,
    expired: Boolean,
    timerHasRestarted: Boolean,
  }
  connect () {
    super.connect()
    console.log('connected to exams')
    // console.log('invisible btn', this.invisibleButtonTarget)
    if (this.expiredValue && !this.timerHasRestartedValue) {
      this.displayTimeExpiredAlert()
    }
    this.renderKatex()
    if (this.hasShowPassageValue && this.showPassageValue) {
      this.hideSidebar()
    }
  }

  answerFrq(e) {
    const target = e.currentTarget;
    this.stimulate('Exams#answer_frq', target)
  }

  answerMathFrq(e) {
    const target = e.currentTarget;
    const { value } = target;
    const valid = this._validateArrangement(value);

    if (valid) {
      this.stimulate('Exams#answer_math_frq', target)
    }
  }

  answerEssay(e) {
    const target = e.currentTarget;
    this.stimulate('Exams#answer_essay', target)
  }

  hideSidebar() {
    let showSidebar = localStorage.getItem('showSidebar')
    showSidebar = showSidebar !== null ? JSON.parse(showSidebar) : true
    console.log('showSidebar in exams controller', showSidebar)
    if (showSidebar) {
      console.log('menu is on, hide it')
      this.dispatch('hideSidebar')
    }
  }

  passageTargetConnected(el) {
    // console.log('passageTargetConnected', el)
    // Not used, use after reflex instead
    this.renderKatex()
  }

  submitLoading(action='show') {
    const el = document.getElementById('calc-score-loading')
    action == 'show' ? el.classList.remove('hidden') : el.classList.add('hidden')
  }

  trySubmitSection(e) {
    const { dataset } = e.target
    console.log(dataset)
    // console.log(document.referrer)
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    // this.loading()
    fetch(`/student_exam_sections/${dataset.studentExamSectionId}/try_submit`, {headers: {"X-CSRF-TOKEN": csrf}})
      .then(res => res.json())
      .then(res => {
        console.log({res})

        if (res.msg === 'ok') {
          // if (confirm(`Submit your answers for this section?`)) {
          //   this.submitSection(e, dataset.studentExamSectionId, dataset.lastSection == 'true', dataset.examSlug)
          // }
          Swal.fire({
            text: `This action cannot be reverted.\nAre you SURE you want to submit ALL your answers for this section?`,
            icon: 'warning',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#D64933',
            showCancelButton: true,
            // reverseButtons: true,
            confirmButtonText: 'Continue',
            confirmButtonColor: '#57A773',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (!result.isConfirmed) {
              this.loading('hide')
              this.restartTimer()
            } else {
              this.loading()
              // Swal.fire('Changes are not saved', '', 'info')
              this.submitSection(e, dataset.studentExamSectionId, dataset.lastSection == 'true', dataset.examSlug)
            }
          })
        } else {
          Swal.fire({
            title: 'STOP!',
            text: `${res.msg}\nAre you SURE you want to submit ALL your answers for this section?`,
            icon: 'error',
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#57A773',
            showCancelButton: true,
            // reverseButtons: true,
            confirmButtonText: 'Continue',
            confirmButtonColor: '#D64933',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (!result.isConfirmed) {
              this.loading('hide')
              this.restartTimer()
            } else {
              // Swal.fire('Changes are not saved', '', 'info')
              Swal.fire({
                icon: 'warning',
                text: 'You have unanswered questions, and this action can NOT be reverted. Are you sure you want to submit?',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#57A773',
                showCancelButton: true,
                // reverseButtons: true,
                confirmButtonText: 'Continue',
                confirmButtonColor: '#D64933',
              }).then((res) => {
                if (res.isConfirmed) {
                  // confirm
                  console.log('confirmed')
                  this.loading()
                  this.submitSection(e, dataset.studentExamSectionId, dataset.lastSection == 'true', dataset.examSlug)
                } else {
                  this.loading('hide')
                  this.restartTimer()
                }
              })
            }
          })
        }
      })
  }

  submitSection(e, sesId, isLastSection, examSlug = null) {
    if (isLastSection) {
      this.stimulate('Exams#finish_exam', e.target, sesId).then(res => {
        this.loading('hide')
        this.clearTimer()
        window.location.href = `/exams/${examSlug}/summary`;
      })
    } else {
      this.stimulate('Exams#submit_section', e.target, sesId).then(res => {
        this.loading('hide')
        this.clearTimer()
        window.location.reload(true)
      })
    }
  }

  exportLoading() {
    // this.loading()
    const el = document.querySelector('a#export-button')
    if (el) {
      el.click()
    }
  }

  displayTimeExpiredAlert() {
    console.log('this.element.dataset', this.element.dataset)
    this.stimulate('Exams#expire_time_limit', this.element).then(res => {
      Swal.fire({
        title: "Time is up!",
        // text: `Your time for this section is up.`,
        text: `Your time for this section is up. You can continue working on the questions, but any response submitted onwards will not count towards your final score.`,
        icon: 'error',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#57A773',
        cancelButtonColor: '#D64933',
        confirmButtonText: 'Submit Answers',
        cancelButtonText: 'Keep working',
      }).then(res => {
        if (res.isConfirmed) {
          console.log('submit answers')
          if (this.hasInvisibleButtonTarget) this.invisibleButtonTarget.click()
        } else {
          console.log('keep working')
          // restart time
          this.restartTimer()
        }
      })
    })
  }

  clearTimer() {
    // dispatch event exams:clearTimer and trigger timer#stopCurrentTimer
    console.log('CLEAR TIMER FROM EXAMS')
    this.dispatch('clearTimer')
  }

  restartTimer() {
    let el;
    if (this.hasInvisibleButtonTarget) el = this.invisibleButtonTarget
    // if (this.hasSubmitBtnTarget) el = this.submitBtnTarget
    // else if (this.hasFinishTestBtnTarget) el = this.finishTestBtnTarget
    // I need el, because el has the ses_id
    //
    if (el) {
      const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      // this.loading()
      fetch(`/student_exam_sections/${el.dataset.studentExamSectionId}/check_expiry`, {headers: {"X-CSRF-TOKEN": csrf}})
        .then(res => res.json())
        .then(res => {
          console.log('res', res)
          if (res.is_expired && !res.restart_time) {
            this.stimulate('Exams#restart_timer', el).then(res => {
              // here dispatch timer restart
              this.dispatch('restartTimer')
            })
          }
        })
    }
  }

  toggleEditScores(e) {
    console.log('edit scores in teachers exams summary page')
    if (this.hasUpdateScoresFormTarget && this.hasScoresSummaryTableTarget && this.hasSummaryButtonsTarget) {
      this.updateScoresFormTarget.classList.toggle('hidden')
      this.scoresSummaryTableTarget.classList.toggle('hidden')
      this.summaryButtonsTarget.classList.toggle('hidden')
      this.editScoresButtonTarget.classList.toggle('hidden')
    }
  }

  updateScores(e) {
    e.preventDefault()
    // console.log('target', e.currentTarget.form)
    const { form } = e.currentTarget
    Swal.fire({
      title: "Update scores?",
      // text: `Your time for this section is up.`,
      text: `Are you sure you want to update the scores for this exam? This action cannot be reverted.`,
      // icon: 'error',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#57A773',
      cancelButtonColor: '#D64933',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then(res => {
      if (res.isConfirmed) {
        form.submit()
      }
    })
  }

  afterContinueTest(reflex, element) {
    console.log('afterContinueTest')
    window.location.reload()
  }

  afterReflex(reflex, element) {
    this.renderKatex()
  }

  afterAdd(reflex, element) {
    // console.log('after add')
    initChoices();
  }
}
