// import { Controller } from "stimulus"
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "wrapper", "menuOff", "menuOn", "nav", "main", "railsAdmin" ]

  connect() {
    super.connect()
    this.mainFrame = document.getElementById("main-frame")
    // console.log('hello sidebar controller, main', this.mainTarget)
    // console.log('hello sidebar controller, mainFrame', this.mainFrame)
    // console.log('main', this.mainTarget)
    let showSidebar = localStorage.getItem('showSidebar')
    showSidebar = showSidebar !== null ? JSON.parse(showSidebar) : true
    // console.log(1, showSidebar, 'type of', typeof showSidebar)
    showSidebar ? this.menuOn() : this.menuOff()
  }

  menuOff(e) {
    // console.log('menuOff')
    localStorage.setItem('showSidebar', 'false')
    this.mainTarget.classList.remove("w-60")
    this.mainTarget.classList.add("w-12")
    this.menuOffTarget.classList.add("hidden")
    this.menuOnTarget.classList.remove("hidden")
    this.navTarget.classList.add("hidden")
    this.mainFrame.style.marginLeft = "4rem"
    if (this.hasRailsAdminTarget) this.railsAdminTarget?.classList?.add("hidden")
  }

  menuOn(e) {
    // console.log('menuOn')
    localStorage.setItem('showSidebar', 'true')
    this.mainTarget.classList.add("w-60")
    this.mainTarget.classList.remove("w-12")
    this.menuOffTarget.classList.remove("hidden")
    this.menuOnTarget.classList.add("hidden")
    this.navTarget.classList.remove("hidden")
    this.mainFrame.style.marginLeft = "15rem"
    if (this.hasRailsAdminTarget) this.railsAdminTarget?.classList?.remove("hidden")
  }
}
